<template>
  <div class="page-header clear-filter" filter-color="orange">
    <div
      class="page-header-image"
      style="background-image: url('img/login.jpg')"
    ></div>
    <div class="container">
      <div class="container">
        <card color="white" plain>
          <div class="col-md-8 ml-auto mr-auto form-group">
            <el-select
              class="select-primary"
              collapse-tags
              v-model="formData.question"
              placeholder="문의유형"
            >
              <el-option
                v-for="option in selects.question"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              >
              </el-option>
            </el-select>

            <fg-input
              v-model="formData.nametitle"
              class="no-border input-lg"
              addon-left-icon="now-ui-icons users_circle-08"
              placeholder="상호/성함/직함"
            >
            </fg-input>

            <fg-input
              v-model="formData.email"
              class="no-border input-lg"
              addon-left-icon="now-ui-icons ui-1_email-85"
              placeholder="이메일"
            >
            </fg-input>

            <fg-input
              v-model="formData.phone"
              class="no-border input-lg"
              addon-left-icon="now-ui-icons tech_mobile"
              placeholder="휴대전화번호"
            >
            </fg-input>

            <div class="textarea-container">
              <textarea
                v-model="formData.message"
                class="form-control"
                name="message"
                rows="10"
                cols="80"
                placeholder="신청내용"
              ></textarea>
            </div>

            <div
              class="alert alert-danger btn-round"
              style="display: none"
              role="alert"
            >
              {{ alert }}
            </div>

            <a
              @click.prevent="onSubmit"
              class="btn btn-primary btn-round btn-lg btn-block"
              >전송</a
            >
          </div>
        </card>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>
<script>
import Vue from "vue";
import { Select, Option } from "element-ui";
import { Card, Button, FormGroupInput } from "@/components";
import MainFooter from "@/layout/MainFooter";
import { VueReCaptcha } from "vue-recaptcha-v3"; // npm install vue-recaptcha-v3@^1.9.0
import axios from 'axios'; // npm i axios

Vue.use(VueReCaptcha, {
  siteKey: "6LcGjM0UAAAAANJh_r_8dSsOS7hB6GHyOUsEoQAC",
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true,
  },
});

export default {
  name: "login-page",
  bodyClass: "login-page",
  components: {
    Card,
    MainFooter,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data: () => ({
    alert: "",
    formData: {
      token: "",
      question: "",
      nametitle: "",
      email: "",
      phone: "",
      message: "",
    },
    selects: {
      question: [
        { value: "서비스 신청/해지", label: "서비스 신청/해지" },
        { value: "개발/유지보수/기술지원", label: "개발/유지보수/기술지원" },
        { value: "제휴제안", label: "제휴제안" },
      ],
    },
  }),
  created: () => {},
  methods: {
    toast(msg) {
      this.alert = msg;
      $(".alert")
        .fadeTo(800, 500)
        .slideUp(500, function () {
          $(".alert").slideUp(500);
        });
    },
    async onSubmit() {
      const re =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // email
      if (this.formData.question.length == 0) {
        this.toast("문의유형을 선택해 주세요.");
        return;
      } else if (this.formData.nametitle.length == 0) {
        this.toast("상호/성함/직함을 입력해 주세요.");
        return;
      } else if (
        this.formData.email.length == 0 ||
        !re.test(this.formData.email)
      ) {
        this.toast("이메일을 확인해 주세요.");
        return;
      } else if (
        this.formData.phone.length == 0 ||
        !/[0-9-]{9,15}/.test(this.formData.phone)
      ) {
        this.toast("휴대전화번호를 확인해 주세요.");
        return;
      } else if (this.formData.message.length == 0) {
        this.toast("내용을 입력해 주세요.");
        return;
      }

      await this.$recaptchaLoaded();
      this.formData.token = await this.$recaptcha("login");

      axios({
        method: "post",
        headers: {
          Accept: "*.*",
          "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        },
        url: `https://api.itax.kr/v1/api/req`,
        data: `v=${encodeURIComponent(JSON.stringify(this.formData))}`, // 서버에서 OPTIONS요청을 못받음. 변경하지 말것.
      })
        .then((res) => {
          if (res.data.status == "S") {
            for (var key in this.formData) {
              this.formData[key] = "";
            }
            alert('전송을 완료 하였습니다.');
          } else {
            this.toast(res.data.msg);
          }
        })
        .catch((error) => {
          this.toast(error);
        });
    },
  },
};
</script>
<style>
.el-input {
  margin-bottom: 20px;
}
.el-input__inner {
  background-color: rgba(255, 255, 255, 0.1) !important;
  padding: 15px !important;
}
</style>
