<template>
  <div>
    <div class="page-header clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image:url('img/header.jpg')"
      >
      </parallax>
      <div class="container d-table" style="padding-bottom: 200px;">
        <div class="brand text-left d-table-cell align-middle">
          <h1 class="title">New Technology Market</h1>
          <p class="text">새로운 기술로 혁신을 가속화 합니다.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Parallax } from '@/components';

export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    Parallax,
  }
};
</script>
<style></style>
