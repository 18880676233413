<template>
  <div>
    <div class="page-header clear-filter page-header-small" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image: url('img/bg5.jpg')"
      >
      </parallax>
    </div>
    <div class="container">
      <h3 class="title text-left">회사소개</h3>
      <h4 class="text-left">
        NTM은 자동화 노하우, 기술력, 서비스 운영 경험을 기반으로 정부기관,
        금융기관, 은행, 보험, 증권사 등을 실시간으로 데이터를 수집하여 최종
        사용자에게 제공하고, 단순 반복적인 작업들을 인공지능, 기계학습등의
        자체 보유 기술로 자동화해 드립니다.
      </h4>
    </div>
    <br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br />
    <br /><br /><br /><br />
  </div>
</template>
<script>
import { Parallax } from "@/components";
export default {
  name: "profile",
  bodyClass: "profile-page",
  components: {Parallax},
};
</script>
<style></style>
