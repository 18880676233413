<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/bg6.jpg')"
      >
      </parallax>
    </div>
    <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">서비스</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/gov.png"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">정부기관 데이터 수집</h4>
                <p class="description">
                  각종 정부기관 (홈택스, 위택스, 정부24, 법원, 지로 등) 데이터를
                  실시간으로 수집하여 제공해 드리고, 수집된 테이터를 관리서버
                  엔드포인트로 전송해 드립니다.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/finance.png"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">금융권 데이터 수집</h4>
                <p class="description">
                  모든 은행, 증권 계좌를 실시간으로 조회하는 엔진을 제공해
                  드리고, 수집된 테이터를 관리서버 엔드포인트로 전송해 드립니다.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/rpa.png"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">로보틱 처리 자동화</h4>
                <p class="description">
                  크롤링, 매크로, 스크린스크래핑, 로봇 프로세스 자동화(RPA) 기술을
                  사용하여 어플리케이션과 웹브라우저의 단순 반복 업무를 자동화해 드립니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput, Parallax } from "@/components";
export default {
  name: "landing",
  bodyClass: "landing-page",
  components: {
    Parallax,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
    };
  },
};
</script>
<style></style>
