import 'bootstrap/dist/css/bootstrap.css';
import '@/assets/scss/now-ui-kit.scss';
import globalMixins from './globalMixins';


export default {
  install(Vue) {
    Vue.use(globalMixins);
  }
};
