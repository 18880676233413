<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link class="navbar-brand" style="background: url(/img/logo.png) no-repeat; background-size: 100% auto; width: 120px;height:32px;" to="/">
        <span class="blind d-none daum-wm-title">엔티엠</span>
      </router-link>
      <!-- <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="200"
        trigger="hover"
      >
        New Technology Market
      </el-popover> v-popover:popover1-->
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <a
          class="nav-link"
          href="/profile"
        >
          <i class="now-ui-icons travel_info"></i>
          <p>NTM</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="/services"
        >
          <i class="now-ui-icons shopping_box"></i>
          <p>Services</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="/contact"
        >
          <i class="now-ui-icons ui-1_send"></i>
          <p>Contact</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { Navbar } from '@/components';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    Navbar
  }
};
</script>

<style scoped></style>
